import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '#app/utils/misc'

const cardVariantsDefinitions = {
	default: 'bg-white dark:bg-gray-900 shadow-xs border',
	moonhub: 'from-brand-start to-brand-end p-[1px] bg-linear-to-r',
} as const

const cardVariantsSizeDefinitions = {
	none: '',
	sm: 'rounded-sm',
	md: 'rounded-md',
	lg: 'rounded-lg',
	xl: 'rounded-xl',
	'2xl': 'rounded-2xl',
	full: 'rounded-full',
} as const

// export type CardVariant = keyof typeof cardVariantsDefinitions

const cardVariants = cva('flex-col flex flex-none', {
	variants: {
		variant: cardVariantsDefinitions,
		size: cardVariantsSizeDefinitions,
	},
	defaultVariants: {
		variant: 'default',
		size: 'none',
	},
})

export interface CardProps
	extends React.BaseHTMLAttributes<HTMLDivElement>,
		VariantProps<typeof cardVariants> {
	innerClassName?: string
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
	({ className, variant, children, innerClassName, size, ...props }, ref) => {
		return (
			<div
				className={cn(cardVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			>
				{variant === 'moonhub' ? (
					<div
						className={cn(
							'bg-white',
							size ? cardVariantsSizeDefinitions[size] : '',
							innerClassName,
						)}
					>
						{children}
					</div>
				) : (
					<>{children}</>
				)}
			</div>
		)
	},
)
Card.displayName = 'Card'

const CardHeader = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			'border-border flex flex-col border-b px-5 py-3',
			className,
		)}
		{...props}
	/>
))
CardHeader.displayName = 'CardHeader'

const CardTitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
	<div className="flex flex-row items-center gap-3">
		<h3 ref={ref} className={cn('text-lg', className)} {...props} />
	</div>
))
CardTitle.displayName = 'CardTitle'

const CardSubtitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
	<p
		ref={ref}
		className={cn('text-sm text-gray-500', className)}
		{...props}
	/>
))
CardSubtitle.displayName = 'CardSubtitle'

const CardDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
	<p
		ref={ref}
		className={cn('text-muted-foreground text-sm', className)}
		{...props}
	/>
))
CardDescription.displayName = 'CardDescription'

const CardContent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div ref={ref} className={cn('p-5', className)} {...props} />
))
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn('flex items-center p-5 pt-0', className)}
		{...props}
	/>
))
CardFooter.displayName = 'CardFooter'

export {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardSubtitle,
	CardTitle,
	cardVariants,
}
